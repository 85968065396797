<template>
  <b-nav v-if="node" vertical>
    <b-row :class="node.children ? 'nav-item-expandable': 'nav-item'" align-v="center">
      <b-button
        v-if="node.children"
        v-b-toggle="collapseId"
        :aria-label="$t('components.navigationMain.toggle')"
        variant="primary"
        class="nav-toggle-sub-menu"
      />
      <b-nav-item :to="{ name: routeName }" :active="active" exact>{{
        $t(localeSection + "." + routeName)
      }}</b-nav-item>
    </b-row>

    <b-collapse v-if="node.children && node.children.length" :id="collapseId">
      <NavigationTreeNode
        v-for="child in node.children"
        :key="child.label"
        :node="child"
        :locale-section="localeSection"
      />
    </b-collapse>
  </b-nav>
</template>

<script>
export default {
  name: "NavigationTreeNode",
  props: {
    node: {
      type: [Object, String],
      required: true
    },
    localeSection: {
      type: String,
      required: true
    }
  },
  computed: {
    collapseId() {
      return "toggle-sub-menu-" + this.routeName;
    },
    routeName() {
      return this.node.to || this.node;
    },
    path() {
      return this.$router.resolve({ name: this.routeName }).route.path;
    },
    active() {
      return (
        this.$route.matched.filter(
          (route) =>
            route.path.startsWith(this.path + "/") || route.path === this.path
        ).length > 0
      );
    }
  }
};
</script>
